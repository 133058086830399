import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { companiesAPI } from "../services/api";

// Asynchronous thunk actions
export const fetchCompanies = createAsyncThunk(
  "companies/fetchCompanies",
  async (_, { rejectWithValue }) => {
    try {
      const response = await companiesAPI.getAllCompanies();
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response.data.message || "Unknown error occurred"
      );
    }
  }
);

export const createCompany = createAsyncThunk(
  "companies/createCompany",
  async (companyData, { rejectWithValue }) => {
    try {
      const response = await companiesAPI.createCompany(companyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response.data.message || "Unknown error occurred"
      );
    }
  }
);

export const getCompanyById = createAsyncThunk(
  "companies/getCompanyById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await companiesAPI.getCompanyById(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response.data.message || "Unknown error occurred"
      );
    }
  }
);

export const updateCompany = createAsyncThunk(
  "companies/updateCompany",
  async ({ id, companyData }, { rejectWithValue }) => {
    try {
      const response = await companiesAPI.updateCompany(id, companyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response.data.message || "Unknown error occurred"
      );
    }
  }
);
//deleteCompany
export const deleteCompany = createAsyncThunk(
  "companies/deleteCompany",
  async (id, { rejectWithValue }) => {
    try {
      await companiesAPI.deleteCompany(id);
      return id;
    } catch (error) {
      return rejectWithValue(
        error.response.data.message || "Unknown error occurred"
      );
    }
  }
);

const companiesSlice = createSlice({
  name: "companies",
  initialState: {
    companies: [],
    loading: false,
    error: null,
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanies.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCompanies.fulfilled, (state, action) => {
        state.loading = false;
        state.companies = action.payload;
      })
      .addCase(fetchCompanies.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createCompany.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCompany.fulfilled, (state, action) => {
        state.loading = false;
        state.companies.push(action.payload);
      })
      .addCase(createCompany.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getCompanyById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCompanyById.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.companies.findIndex(
          (company) => company.id === action.payload.id
        );
        if (index !== -1) {
          state.companies[index] = action.payload;
        } else {
          state.companies.push(action.payload);
        }
      })
      .addCase(getCompanyById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateCompany.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.companies.findIndex(
          (company) => company.id === action.payload.id
        );
        if (index !== -1) {
          state.companies[index] = action.payload;
        }
      })
      .addCase(updateCompany.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearError } = companiesSlice.actions;

export default companiesSlice.reducer;
