// src/api.js
import axiosInstance from "../auth/axiosConfig";

export const authAPI = {
  login: (credentials) => axiosInstance.post("/auth/login", credentials),
  refresh: (token) =>
    axiosInstance.post("/auth/refresh", { refresh_token: token }),
};

export const usersAPI = {
  createUser: (userData) => axiosInstance.post("/users/create", userData),
  getAllUsers: () => axiosInstance.get("/users"),
  getUserById: (id) => axiosInstance.get(`/users/${id}`),
  updateUser: (id, userData) => axiosInstance.patch(`/users/${id}`, userData),
};

export const adminsAPI = {
  createAdmin: (adminData) => axiosInstance.post("/admins/create", adminData),
  getAllAdmins: () => axiosInstance.get("/admins"),
  getAdminById: (id) => axiosInstance.get(`/admins/${id}`),
  updateAdmin: (id, adminData) =>
    axiosInstance.patch(`/admins/${id}`, adminData),
};

export const companiesAPI = {
  createCompany: (companyData) =>
    axiosInstance.post("/company/create", companyData),
  getAllCompanies: () => axiosInstance.get("/company"),
  getCompanyById: (id) => axiosInstance.get(`/company/${id}`),
  updateCompany: (id, companyData) =>
    axiosInstance.patch(`/company/${id}`, companyData),
};

export const associatesAPI = {
  createAssociate: (associateData) =>
    axiosInstance.post("/associates/create", associateData),
  getAllAssociates: () => axiosInstance.get("/associates"),
  getAssociateById: (id) => axiosInstance.get(`/associates/${id}`),
  getAssociatesByCompanyId: (companyId) =>
    axiosInstance.get(`/associates/company/${companyId}`),
  updateAssociate: (id, associateData) =>
    axiosInstance.patch(`/associates/${id}`, associateData),
};

export const servicesAPI = {
  createService: (serviceData) =>
    axiosInstance.post("/services/create", serviceData),
  getAllServices: () => axiosInstance.get("/services"),
  getServiceById: (id) => axiosInstance.get(`/services/${id}`),
  getServicesByCompanyId: (companyId) =>
    axiosInstance.get(`/services/servicesByCompany/${companyId}`),
  updateService: (id, serviceData) =>
    axiosInstance.patch(`/services/${id}`, serviceData),
};

export const appointmentsAPI = {
  createAppointment: (appointmentData) =>
    axiosInstance.post("/appointments/create", appointmentData),
  getAllAppointments: () => axiosInstance.get("/appointments"),
  getAppointmentById: (id) => axiosInstance.get(`/appointments/${id}`),
  getAppointmentsByServiceId: (serviceId) =>
    axiosInstance.get(`/appointments/service/${serviceId}`),
  getAppointmentsByCompanyId: (companyId) =>
    axiosInstance.get(`/appointments/company/${companyId}`),
  getAppointmentsByProfessionalId: (professionalId) =>
    axiosInstance.get(`/appointments/professional/${professionalId}`),
  updateAppointment: (id, appointmentData) =>
    axiosInstance.patch(`/appointments/${id}`, appointmentData),
  deleteAppointment: (id) => axiosInstance.delete(`/appointments/${id}`),
};

export const professionalsAPI = {
  getProfessionalsByCompanyId: (companyId) =>
    axiosInstance.get(`/professional/company/${companyId}`),
  createProfessional: (professionalData) =>
    axiosInstance.post("/professional/create", professionalData),
  updateProfessional: (id, professionalData) =>
    axiosInstance.patch(`/professional/${id}`, professionalData),
  getAllProfessionals: () => axiosInstance.get("/professionals"),
  deleteProfessional: (id) => axiosInstance.delete(`/professionals/${id}`),
  addServiceToProfessional: (id, data) =>
    axiosInstance.patch(`/professional/${id}/add-service`, data),
};

export const availabilityAPI = {
  getAllAvailabilities: () => axiosInstance.get("/availability"),
  getAvailabilityByCompanyId: (id) =>
    axiosInstance.get(`/availability/by-company/${id}`),
  createAvailability: (availabilityData) =>
    axiosInstance.post("/availability/create", availabilityData),
  getAvailabilityByServiceAndProfessional: (serviceId, professionalId) =>
    axiosInstance.get(`/availability/by-service-and-professional`, {
      params: { serviceId, professionalId },
    }),
  updateAvailability: (id, availabilityData) =>
    axiosInstance.patch(`/availability/update/${id}`, availabilityData),
};
