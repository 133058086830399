// src/store/availabilitySlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { availabilityAPI } from "../services/api";

// Asynchronous thunk actions
export const fetchAvailabilities = createAsyncThunk(
  "availability/fetchAvailabilities",
  async (_, { rejectWithValue }) => {
    try {
      const response = await availabilityAPI.getAllAvailabilities();
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response.data.message || "Unknown error occurred"
      );
    }
  }
);

export const createAvailability = createAsyncThunk(
  "availability/createAvailability",
  async (availabilityData, { rejectWithValue }) => {
    try {
      const response = await availabilityAPI.createAvailability(
        availabilityData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response.data.message || "Unknown error occurred"
      );
    }
  }
);

export const getAvailabilityByServiceAndProfessional = createAsyncThunk(
  "availability/getAvailabilityByServiceAndProfessional",
  async (serviceId, professionalId, { rejectWithValue }) => {
    try {
      const response =
        await availabilityAPI.getAvailabilityByServiceAndProfessional(
          serviceId,
          professionalId
        );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response.data.message || "Unknown error occurred"
      );
    }
  }
);

export const getAvailabilityByCompanyId = createAsyncThunk(
  "availability/getAvailabilityByCompanyId",
  async (id, { rejectWithValue }) => {
    try {
      const response = await availabilityAPI.getAvailabilityByCompanyId(id);
      console.log("response by idC estoy en el slice", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response.data.message || "Unknown error occurred"
      );
    }
  }
);

export const updateAvailability = createAsyncThunk(
  "availability/updateAvailability",
  async (availabilityData, { rejectWithValue }) => {
    try {
      const response = await availabilityAPI.updateAvailability(
        availabilityData.id,
        availabilityData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response.data.message || "Unknown error occurred"
      );
    }
  }
);

const availabilitySlice = createSlice({
  name: "availability",
  initialState: {
    availabilities: [],
    loading: false,
    error: null,
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAvailabilities.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAvailabilities.fulfilled, (state, action) => {
        state.loading = false;
        state.availabilities = action.payload;
      })
      .addCase(fetchAvailabilities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createAvailability.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createAvailability.fulfilled, (state, action) => {
        state.loading = false;
        state.availabilities.push(action.payload);
      })
      .addCase(createAvailability.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearError } = availabilitySlice.actions;

export default availabilitySlice.reducer;
