import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form } from "react-bootstrap";

const AddAdmin = ({ adminData, onSave }) => {
  const { t } = useTranslation();
  const [adminInfo, setAdminInfo] = useState(
    adminData || {
      name: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
    }
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAdminInfo({ ...adminInfo, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(adminInfo);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="formName">
        <Form.Label>{t("name")}</Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={adminInfo.name}
          onChange={handleChange}
          required
        />
      </Form.Group>
      <Form.Group controlId="formLastName">
        <Form.Label>{t("lastName")}</Form.Label>
        <Form.Control
          type="text"
          name="lastName"
          value={adminInfo.lastName}
          onChange={handleChange}
          required
        />
      </Form.Group>
      <Form.Group controlId="formEmail">
        <Form.Label>{t("email")}</Form.Label>
        <Form.Control
          type="email"
          name="email"
          value={adminInfo.email}
          onChange={handleChange}
          required
        />
      </Form.Group>
      <Form.Group controlId="formPhone">
        <Form.Label>{t("phone")}</Form.Label>
        <Form.Control
          type="text"
          name="phone"
          value={adminInfo.phone}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group controlId="formPassword">
        <Form.Label>{t("password")}</Form.Label>
        <Form.Control
          type="password"
          name="password"
          value={adminInfo.password}
          onChange={handleChange}
          required
        />
      </Form.Group>
      <div className="mt-3">
        <Button variant="primary" type="submit">
          {t("save")}
        </Button>
      </div>
    </Form>
  );
};

export default AddAdmin;
