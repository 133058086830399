import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Button, Alert, Tab, Nav } from "react-bootstrap";
import { useAuth } from "../../auth/AuthContext";
import AddClient from "../Admin/AddClient/AddClient";
import AddAdmin from "./addAdmin/addAdmin";
import logo from "../../assets/img/logo-venture.png";
import { useDispatch, useSelector } from "react-redux";
import { createAdmin, fetchAdmins, deleteAdmin } from "../../store/adminSlice";
import {
  fetchCompanies,
  deleteCompany,
  createCompany,
} from "../../store/companySlice";

import { useNavigate } from "react-router-dom";
import "./index.scss";

const AdminIndex = () => {
  const { t } = useTranslation();
  const { logout, isAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showClientModal, setShowClientModal] = useState(false);
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [currentClient, setCurrentClient] = useState(null);
  const [currentAdmin, setCurrentAdmin] = useState(null);
  const [alert, setAlert] = useState("");

  const clients = useSelector((state) => state.company.companies);
  const admins = useSelector((state) => state.admins.admins);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        await dispatch(fetchAdmins());
        await dispatch(fetchCompanies());
      } else {
        navigate("/");
      }
    };
    fetchData();
  }, [dispatch, navigate]);

  const handleOpenClientModal = (client = null) => {
    setCurrentClient(client);
    setShowClientModal(true);
  };
  const handleCloseClientModal = () => setShowClientModal(false);

  const handleOpenAdminModal = (admin = null) => {
    setCurrentAdmin(admin);
    setShowAdminModal(true);
  };
  const handleCloseAdminModal = () => setShowAdminModal(false);

  const handleSaveClient = (clientInfo) => {
    console.log("Client Info:", clientInfo);
    //dayana
    const resp = dispatch(createCompany(clientInfo));
    console.log("Respuesta:", resp);
    handleCloseClientModal();
  };

  const handleSaveAdmin = async (adminInfo) => {
    try {
      const resp = await dispatch(createAdmin(adminInfo));
      if (resp.payload) {
        setAlert("Admin created successfully");
      }
    } catch (error) {
      setAlert(t("error_occurred"));
    }
    handleCloseAdminModal();
  };

  const handleDeleteAdmin = async (adminId) => {
    if (admins.length <= 1) {
      setAlert(t("at_least_one_admin_required"));
      return;
    }
    try {
      await dispatch(deleteAdmin(adminId));
      setAlert(t("admin_deleted"));
    } catch (error) {
      setAlert(t("error_occurred"));
    }
  };

  const handleDeleteClient = async (clientId) => {
    try {
      await dispatch(deleteCompany(clientId));
      setAlert(t("client_deleted"));
    } catch (error) {
      setAlert(t("error_occurred"));
    }
  };

  const handleLogout = () => {
    logout();
    navigate("/"); // Redirigir al login después del logout
  };

  return (
    <div className="admin-index">
      <header className="d-flex justify-content-between align-items-center">
        <img src={logo} alt="VENS Logo" width={150} className="mb-4" />
        <div>
          <Button variant="danger" onClick={handleLogout}>
            {t("logout")}
          </Button>
        </div>
      </header>
      <div className="summary">
        <div className="card">
          <h3>{t("users")}</h3>
          <p>0</p>
          <p className="text-danger">-1.15% {t("last_week")}</p>
        </div>
        <div className="card">
          <h3>{t("appointments")}</h3>
          <p>4500</p>
          <p className="text-success">+9.65% {t("last_week")}</p>
        </div>
        <div className="card">
          <h3>{t("monthly_revenue")}</h3>
          <p>$12,029,500</p>
          <p className="text-success">+4.5% {t("last_week")}</p>
        </div>
      </div>
      <div>{alert && <Alert variant="danger">{alert}</Alert>}</div>
      <Tab.Container defaultActiveKey="clients">
        <Nav variant="tabs">
          <Nav.Item>
            <Nav.Link eventKey="clients">{t("clients")}</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="admins">{t("admins")}</Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="clients">
            <div className="clients">
              <h3>{t("clients")}</h3>
              <Button variant="primary" onClick={() => handleOpenClientModal()}>
                {t("add_client")}
              </Button>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>{t("active")}</th>
                      <th>{t("name_or_business")}</th>
                      <th>{t("email")}</th>
                      <th>{t("actions")}</th>
                    </tr>
                  </thead>
                  {clients && clients.length > 0 && (
                    <tbody>
                      {clients.map((client) => (
                        <tr key={client.id}>
                          <td>{client.active ? t("active") : t("inactive")}</td>
                          <td>{client.name}</td>
                          <td>{client.email}</td>
                          <td>
                            <Button
                              variant="link"
                              onClick={() => handleOpenClientModal(client)}
                            >
                              {t("edit")}
                            </Button>
                            <Button
                              variant="link"
                              onClick={() => handleDeleteClient(client.id)}
                            >
                              {t("delete")}
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="admins">
            <div className="admins">
              <h3>{t("admins")}</h3>
              <Button variant="primary" onClick={() => handleOpenAdminModal()}>
                {t("add_admin")}
              </Button>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>{t("name")}</th>
                      <th>{t("email")}</th>
                      <th>{t("phone")}</th>
                      <th>{t("actions")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {admins.map((admin) => (
                      <tr key={admin.id}>
                        <td>{admin.name}</td>
                        <td>{admin.email}</td>
                        <td>{admin.phone}</td>
                        <td>
                          <Button
                            variant="link"
                            onClick={() => handleOpenAdminModal(admin)}
                          >
                            {t("edit")}
                          </Button>
                          <Button
                            variant="link"
                            onClick={() => handleDeleteAdmin(admin.id)}
                          >
                            {t("delete")}
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
      <Modal show={showClientModal} onHide={handleCloseClientModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t("add_client")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddClient clientData={currentClient} onSave={handleSaveClient} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseClientModal}>
            {t("close")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showAdminModal} onHide={handleCloseAdminModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t("add_admin")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddAdmin adminData={currentAdmin} onSave={handleSaveAdmin} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAdminModal}>
            {t("close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AdminIndex;
