import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Esto usa localStorage por defecto
import authReducer from "./authSlice";
import appointmentsReducer from "./appointmentsSlice";
import servicesReducer from "./servicesSlice";
import professionalReducer from "./professionalSlice";
import availabilityReducer from "./availabilitySlice";
import adminReducer from "./adminSlice";
import companyReducer from "./companySlice";

const persistConfig = {
  key: "root",
  storage, // Usa localStorage para persistir el estado
  whitelist: ["auth"], // Lista blanca de los reductores que queremos persistir
};

const persistedReducer = persistReducer(persistConfig, authReducer);

const store = configureStore({
  reducer: {
    auth: persistedReducer,
    appointments: appointmentsReducer,
    services: servicesReducer,
    professionals: professionalReducer,
    availability: availabilityReducer,
    admins: adminReducer,
    company: companyReducer,
    // otros reducers...
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
      },
    }),
});

const persistor = persistStore(store);

export { store, persistor };
