// src/components/Admin/AdminLogin/login.js
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
// import { useAuth } from "../../auth/AuthContext";
import { useNavigate } from "react-router-dom";
import "./login.scss";
import { useDispatch } from "react-redux";
import logo from "../../assets/img/logo-vens.png";
import { login } from "../../store/authSlice";

const AdminLogin = () => {
  const { t } = useTranslation();
  // const { login } = useAuth();
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [success, setSuccess] = useState("");

  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (!email || !password) {
      setError(t("error_fill_all_fields"));
      return;
    }

    try {
      const resultAction = await dispatch(login({ email, password }));

      if (login.fulfilled.match(resultAction)) {
        setSuccess(t("login_success"));
        setTimeout(() => {
          navigate("/index");
        }, 3000);
      } else if (login.rejected.match(resultAction)) {
        setSuccess("");
      }
    } catch (error) {
      console.log(error);
      setError(t("error_invalid_credentials"));
    }
  };

  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <div className="row justify-content-center w-100">
        <div className="col-12 text-center mb-4">
          <img src={logo} alt="VENS Logo" width={300} className="logo" />
        </div>
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-body">
              {error && <div className="alert alert-danger">{error}</div>}
              {success && <div className="alert alert-success">{success}</div>}

              <form onSubmit={handleSubmit}>
                <div className="form-group mb-3">
                  <label htmlFor="email">{t("email")}</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder={t("email")}
                    value={email}
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="password">{t("password")}</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder={t("password")}
                    value={password}
                    required
                  />
                </div>
                <button type="submit" className="btn btn-primary w-100">
                  {t("login_button")}
                </button>
              </form>
              {/* <BiometricAuth onSuccess={handleBiometricSuccess} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
