import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { adminsAPI } from "../services/api";

// Asynchronous thunk actions
export const fetchAdmins = createAsyncThunk(
  "admins/fetchAdmins",
  async (_, { rejectWithValue }) => {
    try {
      const response = await adminsAPI.getAllAdmins();
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response.data.message || "Unknown error occurred"
      );
    }
  }
);

export const createAdmin = createAsyncThunk(
  "admins/createAdmin",
  async (adminData, { rejectWithValue }) => {
    try {
      const response = await adminsAPI.createAdmin(adminData);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response.data.message || "Unknown error occurred"
      );
    }
  }
);

export const fetchAdminById = createAsyncThunk(
  "admins/fetchAdminById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await adminsAPI.getAdminById(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response.data.message || "Unknown error occurred"
      );
    }
  }
);

export const updateAdmin = createAsyncThunk(
  "admins/updateAdmin",
  async ({ id, adminData }, { rejectWithValue }) => {
    try {
      const response = await adminsAPI.updateAdmin(id, adminData);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response.data.message || "Unknown error occurred"
      );
    }
  }
);

//deleteAdmin

export const deleteAdmin = createAsyncThunk(
  "admins/deleteAdmin",
  async (id, { rejectWithValue }) => {
    try {
      const response = await adminsAPI.deleteAdmin(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response.data.message || "Unknown error occurred"
      );
    }
  }
);

const adminsSlice = createSlice({
  name: "admins",
  initialState: {
    admins: [],
    loading: false,
    error: null,
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdmins.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAdmins.fulfilled, (state, action) => {
        state.loading = false;
        state.admins = action.payload;
      })
      .addCase(fetchAdmins.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.admins.push(action.payload);
      })
      .addCase(createAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchAdminById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAdminById.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.admins.findIndex(
          (admin) => admin.id === action.payload.id
        );
        if (index !== -1) {
          state.admins[index] = action.payload;
        } else {
          state.admins.push(action.payload);
        }
      })
      .addCase(fetchAdminById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAdmin.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.admins.findIndex(
          (admin) => admin.id === action.payload.id
        );
        if (index !== -1) {
          state.admins[index] = action.payload;
        }
      })
      .addCase(updateAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearError } = adminsSlice.actions;

export default adminsSlice.reducer;
