import React from "react";
import { Routes, Route } from "react-router-dom";
import AdminLogin from "./components/AdminLogin/login";
import AdminIndex from "./components/Admin/index";
import "./App.scss";
import "./i18n/i18n";
import { useSelector } from "react-redux";

function App() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return (
    <div className="App">
      <Routes>
        <Route path="/ventureLogin" element={<AdminLogin />} />
        <Route path="/index" element={<AdminIndex />} />
        {/* <Route
          path="/index"
          element={isAuthenticated ? <AdminIndex /> : <AdminLogin />}
        /> */}
        <Route path="/login" element={<AdminLogin />} />
        <Route path="/" element={<AdminLogin />} />
      </Routes>
    </div>
  );
}

export default App;
